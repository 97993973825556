const memory = (value) => {
    const list = value.split('.')
    let str = list[1].match(/\d+/)[0]
    str = (str*1024/(10**str.length)).toFixed(0)
    return `${list[0]}GB ${str}MB`
}


const memoryFilter = (value) => {
    let val = parseFloat(value)
    if(val >= 1){
        return `${val}GB`
    }else{
        return `${Math.round(val*1024)}MB`
    }
}


const bigNumber = (value) => {
        if (typeof value === "number") {
            value = value.toString
        }
        const list = value.split('')
        for (let i = 2;i<list.length;i=i+3){
            list.splice(i+1,0,',')
            i++
        }
        return list.join('')
}
export  {
    memory,
    bigNumber,
    memoryFilter
}
