<template>
    <v-container class="home-container">
        <v-layout column >            
			<v-flex mb-4 class="banner">
				<v-carousel class="bannerBox" hide-delimiters height="300px" dark="true" interval="7000">
					<v-carousel-item v-for="(item,i) in bannerItem" :key="i" class="bannerImg" :style="`background: url(${item.src}) no-repeat 100% 0%;`">
						<v-layout class="banner-content" align-center>
						<v-flex pl-5>
							<p class="mb-0 fs-2 font-weight-bold"><span class="fs-yellow">{{item.construction}} </span>{{item.title}}</p>
							<p class="fs-3 font-weight-light">{{item.description}}</p>
							<v-btn class="ma-0" :to="`/check/${item.productName}/-1`" :title="`Order ${item.productName}`">Order Now</v-btn>
						</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>
			</v-flex>
            <v-flex class="apps">
                <v-subheader light class="title px-0 mb-3  font-weight-black " style="justify-content: space-between;">
                    <span>Latest 4 Active Applications</span>
                    <v-spacer></v-spacer>
                    <span :title="`Check more applications.`"><router-link class="body-1" to="/applications">More</router-link></span>
                </v-subheader>
                <v-sheet color="transparent">
                    <v-container grid-list-lg fluid class="pa-0">
                        <v-layout row wrap>
                            <template v-if="appLoading">
                                <v-flex xs12 md12 lg6 v-for="n in 4" :key="n">
                                    <fy-vue-skeleton
                                            animation="raceBlack"
                                            :childOptions="skeletonOptions"
                                    ></fy-vue-skeleton>
                                </v-flex>
                            </template>
                            <template v-else-if="appList && appList.length!==0">
                                <v-flex xs12 md12 lg6 v-for="app in appList" :key="app.name">
                                    <v-card hover class="app-item " style="height: 100%">
                                        <div style="height: 100%" class="pa-0">
                                            <v-layout row style="height: 100%" class="mx-0 mt-0">
                                                <v-flex class=" app-item-left" pr-0>
                                                    <span>  <v-img width="60"
                                                                   :src="getProductNotext(app.product_name)"></v-img></span>
                                                </v-flex>
                                                <v-flex class="app-item-right d-flex" pa-0
                                                        style="background-color: #f0f2ff;">
                                                    <div class="right-inner">
                                                        <div class="right-bd">
                                                            <v-card class="elevation-0" color="transparent">
                                                                <v-card-title class="py-0">
                                                                    <v-subheader
                                                                            class=" subheading pa-0 font-weight-black ">
                                                                        {{app.name}}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <span v-if="getPackageStatus(app) !== 'process'" :style="{color: colorMap[getPackageStatus(app)]}">
                                                                            {{getPackageStatus(app)}}
                                                                        </span>
                                                                    </v-subheader>
                                                                </v-card-title>
                                                                <v-card-text class="py-0">
                                                                    <table class="prop-table">
                                                                        <thead>
                                                                        <tr>
                                                                            <th width="100px"></th>
                                                                            <th></th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td class="py-1 subheading  font-weight-black body-2 title">
                                                                                Created
                                                                            </td>
                                                                            <td class="pl-1 body-1 content">
                                                                                {{app.created | formatDate}}
                                                                            </td>
                                                                            <td class="py-1 subheading  font-weight-black body-2 title">
                                                                                Remain
                                                                            </td>
                                                                            <td class="pl-1 body-1 content">
                                                                                <span :style="{color: app.remain_days>=0?'black':'red'}">{{Math.abs(app.remain_days)}}</span>
                                                                                day(s)
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="py-1 subheading  font-weight-black body-2 title">
                                                                                Configuration
                                                                            </td>
                                                                            <td class="pl-1 body-1 content" colspan="3">
                                                                                {{`(${app.plan_name}) ${app.cpu} CPU
                                                                                Cores / ${memoryFilter(app.memory)} RAM
                                                                                / ${memoryFilter(app.disk)} SSD`}}
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </v-card-text>
                                                            </v-card>
                                                        </div>
                                                        <div class="right-ft">
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" :to="`/applications/${app.uuid}`" outline
                                                                           :disabled="getPackageStatus(app)!=='Active'"
                                                                           color="primary" class="text-capitalize"
                                                                           style="width: 140px">manage
                                                                    </v-btn>
                                                                </template>
                                                                <span>Click to access your application control panel</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </div>
                                                </v-flex>
                                            </v-layout>
                                        </div>
                                    </v-card>
                                </v-flex>
                            </template>
                            <template v-else>
                                <v-flex style="height: 200px">
                                    <Empty>
                                        <p>You do not have an active application</p>
                                        <v-btn color="primary" class="text-capitalize" to="/ordering">Order a New
                                            Product
                                        </v-btn>
                                    </Empty>
                                </v-flex>
                            </template>
                        </v-layout>
                    </v-container>
                </v-sheet>
            </v-flex>
            <v-divider class="my-4"></v-divider>
            <v-flex class="invoices">
                <v-subheader class="title px-0 mb-3 font-weight-black " style="justify-content: space-between;">
                    <span>Latest 5 Unpaid Invoices</span>
                    <v-spacer></v-spacer>
                    <span :title="`Check more invoices.`"><router-link class="body-1" to="/billing/invoices">More</router-link></span>
                </v-subheader>
                <v-sheet color="transparent">
                    <v-card>
                        <v-data-table class="elevation-0 invoice-table"  :headers="headers"
                                      :items="invoicesList"
                                      headers-length="50px" hide-actions>
                            <template v-slot:items="{item}">
                                <tr>
                                    <td>{{ item.id }}</td>
                                    <td class="text-xs-left" style="white-space: nowrap;">{{ (item.packages &&
                                        item.packages.length) ? item.packages[0].name : '--'}}
                                    </td>
                                    <td class="text-xs-left">{{ (item.packages && item.packages.length) ?
                                        item.packages[0].product_name : '--'}}
                                    </td>
                                    <td class="text-xs-left">{{formatDate( item.due_date )}}</td>
                                    <td class="text-xs-left">${{ item.total }}</td>
                                    <td class="text-xs-right">
                                        <v-btn color="primary" :title="`Pay the invoice to extend the due date of the application.`" class="text-capitalize"
                                               :to="`/invoice-detail/${item.uuid}`" small style="width: 140px"> pay now
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <div style="min-height: 400px;position: relative;" v-show="invoicesLoading">
                                    <fy-vue-skeleton
                                            animation="fade"
                                            :rowOptions="{padding : '16px' ,gutter : '40'}"
                                            :childOptions="[{type: 'repeat',repeat: 5, list: [
                                    { span: 24, type: 'spaceline', options: { height: '30px', width:'100%' } },
                                    { span: 24, type: 'card', options: { height: '30px', width:'100%' } },]}]"></fy-vue-skeleton>
                                </div>
                                <div style="min-height: 200px;position: relative;" v-show="!invoicesLoading">
                                    <Empty>You have no unpaid invoices at the moment.</Empty>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-sheet>
            </v-flex>
            <v-divider class="my-4"></v-divider>
            <v-flex class="help-kb">
                <v-subheader class=" title px-0 mb-3 font-weight-black " style="justify-content: space-between;">
                    <span>Help KB</span>
                    <v-spacer></v-spacer>
                    <span :title="`Check more documentation.`"><router-link class="body-1" to="/document">More</router-link></span>
                </v-subheader>
                <v-sheet color="transparent">
                    <v-container grid-list-xl fluid class="pa-0">
                        <v-layout row wrap>
                            <v-flex class="kb-box" xs12 sm6 md6 v-for="kb  in kbs" :key="kb.title">
                                <a :href="kb.link" target="_blank" class=" kb-title subheading px-0 font-weight-black "><div class=" kb-title subheading px-0 font-weight-black ">{{kb.title}}</div></a>
                                <p class="body-1" style=" color: rgba(0,0,0,.54)">{{kb.content}}</p>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-sheet>
            </v-flex>
            <v-divider class="my-4"></v-divider>
            <v-flex class="popular">
                <v-subheader class=" title px-0 mb-3 font-weight-black " style="justify-content: space-between;">
                    <span>Popular Products</span>
                    <v-spacer></v-spacer>
                    <span :title="`Check more products.`"><router-link class="body-1" to="/ordering">More</router-link></span>
                </v-subheader>
                <v-sheet color="transparent">
                    <v-container grid-list-xl fluid class="pa-0">
                        <v-layout row wrap>
                            <v-flex xs6 sm4 md3 lg2 v-for="prod in PopularProd" :key="prod.productName">

                                <Product :product="prod"></Product>

<!--                                <div class="product">-->
<!--                                    <v-img width="160" :src="prod.logo"></v-img>-->
<!--                                    <div class="product-mask">-->
<!--                                        <v-btn v-if="prod.productName.toLowerCase() !== 'phpclusters' && prod.productName.toLowerCase() !=='javaclusters' && prod.productName.toLowerCase() !== 'pythonclusters' &&prod.productName.toLowerCase() !=='aspnetclusters' && prod.productName.toLowerCase() !=='nodejsclusters'" :href="isDev?prod.dev_url:prod.url" target="_blank" color="#fff"-->
<!--                                               class="text-capitalize" small style="width: 140px" outline>more details-->
<!--                                        </v-btn>-->
<!--                                        <v-btn :to="`/check/${prod.productName}/-1`" color="primary" :title="prod.title"-->
<!--                                               class="text-capitalize" small style="width: 140px">order now-->
<!--                                        </v-btn>-->
<!--                                    </div>-->
<!--                                </div>-->
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-sheet>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapActions, mapState, mapGetters} from 'vuex'
    import {memoryFilter} from '@/module/filters/filter'
    import {products ,colorMap} from "@/module/constant"
    import {formatDate} from '@/module/utils/date'
    import Empty from '@/components/Empty'
    import Product from '@/components/Product'
    // import Spin from '@/components/Spin'
    import {isDev} from '@/module/constant'

    const skeletonOptions = [{
        span: 4,
        type: "circle",
        justify: 'center',
        align: 'center',
        options: {
            width: "60px",
            height: "60px",
            borderRadius: "50%"
        }
    }, {
        span: 20,
        type: "more",
        children: [{
            type: "list", options: {
                list: [
                    {"width": "30%", "height": "16px"},
                ],
            }
        }, {
            type: "spaceline",
            options: {
                "height": "10px"
            }
        }, {
            type: "list", options: {
                list: [
                    {"width": "100%", "height": "12px"},
                    {"width": "100%", "height": "12px"},
                ],
            }
        }, {
            type: "spaceline", options: {
                height: "15px"
            }
        }, {
            type: "tag",
            span: '24',
            options: {
                justify: "flex-end",
                list: [{
                    height: "26px",
                    width: '140px',
                    'margin-bottom': '0'
                }],
            }
        }]
    }]
    const headers = [
        {text: 'ID', sortable: false, value: 'id'},
        {text: 'Application Name', sortable: false, value: 'id'},
        {text: 'Product', sortable: false, value: 'id'},
        {text: 'Due Date', sortable: false, value: 'dueDate'},
        {text: 'Total', sortable: false, value: 'total'},
        {text: '', sortable: false, value: 'op'},
    ]

    const kbs = [
        {
            title: 'Continuing Your Services After the Free Trial',
            link:'https://www.cloudclusters.io/docs/general/test1600314645.html',
            content : 'If you are satisfied with our products in the free trial, please don’t hesitate to activate your paid account to unlock more features and subscribe to our service by following the instructions in this help article.',
        },
        {
            title : 'Restoring a Canceled Application',
            link:'https://www.cloudclusters.io/docs/general/1111600325382.html',
            content : 'A service may be canceled for any reason. We will usually keep a backup of your service data for 2 months after the cancellation in case you need them later. Check this documentation to see how you can restore your service with one click.',
        },
        {
            title : 'Payment Issues(PayPal/Credit Card)',
            link:'https://www.cloudclusters.io/docs/general/New%20node1600397835.html',
            content : 'Things may happen that your service got suspended even canceled when you missed a payment or a payment failed. The guide shows you how to automate your payments by setting up PayPal subscription & default credit cards and the steps you can take to resolve the payment failure.',
        },
        {
            title : 'Managing Your Application in the Control Panel',
            link:'https://www.cloudclusters.io/docs/general/Managing%20Your%20Application%20in%20the%20Control%20Panel1600413672.html',
            content : 'We continually add and perfect application management features in our control panel, including FTPS, Shell/SSH, Firewall Rules, and more. Click to see these features you can use to manage your application.',
        },
    ]



    export default {
        name: 'Home',
        components: {Empty,Product},
        data() {
            return {
                isDev,
                products,                
                ready: false,
                appLoading: true,
                invoicesLoading: true,
                appList: null,
                invoicesList: [],
				bannerItem:[
					{
						productName:'nodejsclusters',
						construction:"Node.js",
						src:"https://images.cloudclusters.io/72dc516f08c34a38ab65d5ae02c97fd8/nodejs.jpg",
						title:'Runtime Environment',
						description:'Host customized applications built with node.js.'
					},
					{
						productName:'pythonclusters',
						construction:"Python & uWSGI",
						src:"https://images.cloudclusters.io/958b57838cb64089acc5072ec7640759/python.jpg",
						title:'Runtime Environment',
						description:'Host customized applications built with Python language.'
					},
					{
						productName:'phpclusters',
						construction:"PHP & Apache",
						src:"https://images.cloudclusters.io/98a64fc0b059442fbf8ea17e69b25898/phpApache.jpg",
						title:'Runtime Environment',
						description:'Host customized applications built with PHP language.'
					},
					{
						productName:'javaclusters',
						construction:"Java & Tomcat",
						src:"https://images.cloudclusters.io/2eb97cf7c54340e2a30cd22d914ea43f/java.jpg",
						title:'Runtime Environment',
						description:'Host customized applications built with Java language.'
					},
					{
						productName:'aspnetclusters',
						construction:"ASP.NET Core & Kestrel",
						src:"https://images.cloudclusters.io/7894f26c6c25404fb1ed48da8c7123be/aspnet.jpg",
						title:'Runtime Environment',
						description:'Host customized applications built with ASP.NET Core.'
					}
					
				]
            }
        },
        computed: {
            PopularProd(){
                let arr = Object.values(products)
                return arr.filter( (o) => {
                    return o.productName !== 'MongoDB'
                })
            },			
            ...mapGetters('application', ['getProductNotext','getPackageStatus']),
            ...mapState('application', ['applicationList']),
            ...mapState('client', ['clientInfo']),

        },
        methods: {
            ...mapActions('application', ['getApplicationList']),
            formatDate, memoryFilter,
            getActiveApps() {
                this.$http.get('/packages/', {
                    params: {
                        client_id: this.clientInfo.id,
                        status: 'Active',
                        page_size: 4
                    }
                }).then(({results}) => {
                    this.appLoading = false
                    this.appList = results
                }).catch(e => {
                    this.appLoading = false
                    this.$message.error(e.detail)
                })
            },
            getInvoices() {
                this.$http.get('/invoices/', {
                    params: {
                        client_id: this.$store.state.client.clientInfo.id,
                        status: 'Unpaid',
                    }
                }).then(({results}) => {
                    this.invoicesLoading = false
                    this.invoicesList = results.slice(0,5)
                }).catch(err => {
                    this.invoicesLoading = false
                    this.$message.error(err.detail)
                })
            }
        },
        created() {
            this.kbs = kbs
            this.colorMap = colorMap
            this.headers = headers
            this.skeletonOptions = skeletonOptions
            this.getInvoices()
            this.getActiveApps()
            this.$SDK.track({
                pageName : 'Home'
            })
        }
    }
</script>
<style lang="scss">
    .home-container {
        .title, .subheading {
            color: #333;
        }
        .banner {
            position: relative;
			.bannerBox{
				border-radius: 6px;
				.bannerImg{
					background-size: cover !important;
				}
			}
            .banner-content {
                position: absolute;
                top: 90px;
                left: 0;
				.fs-yellow{
					color: #ffff5d;
					text-shadow: 2px 2px 0px rgba(0,0,0,0.3);
					font-size: 32px;
				}
				.bg-red{
					background-color: #ff5050;
				}
                p {
                    color: white;
                }
            }
        }
        .apps {
            .app-item {
                cursor: default;
                .app-item-left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 90px;
                }

                .app-item-right {
                    .right-inner {
                        $bh: 60px;
                        padding-bottom: $bh;
                        position: relative;
                        max-width: 100%;
                        .right-bd {
                            height: 100%;
                            width: 100%;

                            .prop-table {
                                max-width: 100%;
                                margin-bottom: 10px;

                                tr td.title {
                                    min-width: 30%;
                                    white-space: nowrap;
                                    color: #acadb0;
                                }

                                tr td.content {
                                    word-break: break-all;
                                    color: #333;
                                }
                            }
                        }

                        .right-ft {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: $bh;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                        }
                    }

                }

            }
        }

        .invoices {
            .invoice-table {
                thead tr {
                    background-color: #e3e7ff;

                    &:first-child {
                        border-bottom: 1px solid transparent;
                    }

                    th {
                        color: #333;
                        font-size: 14px;
                        font-weight: 900;
                    }
                }

                tbody tr td {
                    height: 70px;
                }
            }
        }

        .help-kb {
            .kb-box{
                .kb-title {
                    margin-bottom: 10px;
                    word-break: break-all;
                    text-decoration: none;
                }
                &:hover{
                    .kb-title{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
</style>

