<template>
    <div class="product">
        <v-img width="160" :src="product.logo"></v-img>
        <div class="product-mask">
            <v-btn v-if="product.url" :href="isDev?product.dev_url:product.url" target="_blank" color="#fff"
                   class="text-capitalize" small style="width: 140px" outline>more details
            </v-btn>
            <v-btn :to="`/check/${product.productName}/-1`" color="primary" :title="product.title"
                   class="text-capitalize" small style="width: 140px">order now
            </v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Product",
        props: {
            product:Object
        }
    }
</script>

<style lang='scss'>
    .product {
        background-color: #ffffff;
        border-radius: 10px;
        width: 160px;
        height: 160px;
        overflow: hidden;
        margin: 0 auto;
        position: relative;
        .product-mask {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            background-color: rgba(0, 0, 0, .4);
        }

        &:hover {
            .product-mask {
                display: flex;
            }
        }
    }

</style>