var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "home-container" },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "banner", attrs: { "mb-4": "" } },
            [
              _c(
                "v-carousel",
                {
                  staticClass: "bannerBox",
                  attrs: {
                    "hide-delimiters": "",
                    height: "300px",
                    dark: "true",
                    interval: "7000"
                  }
                },
                _vm._l(_vm.bannerItem, function(item, i) {
                  return _c(
                    "v-carousel-item",
                    {
                      key: i,
                      staticClass: "bannerImg",
                      style:
                        "background: url(" + item.src + ") no-repeat 100% 0%;"
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "banner-content",
                          attrs: { "align-center": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { "pl-5": "" } },
                            [
                              _c(
                                "p",
                                { staticClass: "mb-0 fs-2 font-weight-bold" },
                                [
                                  _c("span", { staticClass: "fs-yellow" }, [
                                    _vm._v(_vm._s(item.construction) + " ")
                                  ]),
                                  _vm._v(_vm._s(item.title))
                                ]
                              ),
                              _c(
                                "p",
                                { staticClass: "fs-3 font-weight-light" },
                                [_vm._v(_vm._s(item.description))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-0",
                                  attrs: {
                                    to: "/check/" + item.productName + "/-1",
                                    title: "Order " + item.productName
                                  }
                                },
                                [_vm._v("Order Now")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "apps" },
            [
              _c(
                "v-subheader",
                {
                  staticClass: "title px-0 mb-3  font-weight-black ",
                  staticStyle: { "justify-content": "space-between" },
                  attrs: { light: "" }
                },
                [
                  _c("span", [_vm._v("Latest 4 Active Applications")]),
                  _c("v-spacer"),
                  _c(
                    "span",
                    { attrs: { title: "Check more applications." } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "body-1",
                          attrs: { to: "/applications" }
                        },
                        [_vm._v("More")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-sheet",
                { attrs: { color: "transparent" } },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-0",
                      attrs: { "grid-list-lg": "", fluid: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _vm.appLoading
                            ? _vm._l(4, function(n) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: n,
                                    attrs: { xs12: "", md12: "", lg6: "" }
                                  },
                                  [
                                    _c("fy-vue-skeleton", {
                                      attrs: {
                                        animation: "raceBlack",
                                        childOptions: _vm.skeletonOptions
                                      }
                                    })
                                  ],
                                  1
                                )
                              })
                            : _vm.appList && _vm.appList.length !== 0
                            ? _vm._l(_vm.appList, function(app) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: app.name,
                                    attrs: { xs12: "", md12: "", lg6: "" }
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "app-item ",
                                        staticStyle: { height: "100%" },
                                        attrs: { hover: "" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pa-0",
                                            staticStyle: { height: "100%" }
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass: "mx-0 mt-0",
                                                staticStyle: { height: "100%" },
                                                attrs: { row: "" }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      " app-item-left",
                                                    attrs: { "pr-0": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      [
                                                        _c("v-img", {
                                                          attrs: {
                                                            width: "60",
                                                            src: _vm.getProductNotext(
                                                              app.product_name
                                                            )
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "app-item-right d-flex",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#f0f2ff"
                                                    },
                                                    attrs: { "pa-0": "" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "right-inner"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "right-bd"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "elevation-0",
                                                                attrs: {
                                                                  color:
                                                                    "transparent"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card-title",
                                                                  {
                                                                    staticClass:
                                                                      "py-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      {
                                                                        staticClass:
                                                                          " subheading pa-0 font-weight-black "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                                                        " +
                                                                            _vm._s(
                                                                              app.name
                                                                            ) +
                                                                            "    \n                                                                        "
                                                                        ),
                                                                        _vm.getPackageStatus(
                                                                          app
                                                                        ) !==
                                                                        "process"
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                style: {
                                                                                  color:
                                                                                    _vm
                                                                                      .colorMap[
                                                                                      _vm.getPackageStatus(
                                                                                        app
                                                                                      )
                                                                                    ]
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.getPackageStatus(
                                                                                        app
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                                        "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-card-text",
                                                                  {
                                                                    staticClass:
                                                                      "py-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "table",
                                                                      {
                                                                        staticClass:
                                                                          "prop-table"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "thead",
                                                                          [
                                                                            _c(
                                                                              "tr",
                                                                              [
                                                                                _c(
                                                                                  "th",
                                                                                  {
                                                                                    attrs: {
                                                                                      width:
                                                                                        "100px"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "th"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "tbody",
                                                                          [
                                                                            _c(
                                                                              "tr",
                                                                              [
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-1 subheading  font-weight-black body-2 title"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                Created\n                                                                            "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-1 body-1 content"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                " +
                                                                                        _vm._s(
                                                                                          _vm._f(
                                                                                            "formatDate"
                                                                                          )(
                                                                                            app.created
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                                                            "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-1 subheading  font-weight-black body-2 title"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                Remain\n                                                                            "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-1 body-1 content"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        style: {
                                                                                          color:
                                                                                            app.remain_days >=
                                                                                            0
                                                                                              ? "black"
                                                                                              : "red"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            Math.abs(
                                                                                              app.remain_days
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                                                                day(s)\n                                                                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "tr",
                                                                              [
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-1 subheading  font-weight-black body-2 title"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                Configuration\n                                                                            "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pl-1 body-1 content",
                                                                                    attrs: {
                                                                                      colspan:
                                                                                        "3"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                                " +
                                                                                        _vm._s(
                                                                                          "(" +
                                                                                            app.plan_name +
                                                                                            ") " +
                                                                                            app.cpu +
                                                                                            " CPU\n                                                                                Cores / " +
                                                                                            _vm.memoryFilter(
                                                                                              app.memory
                                                                                            ) +
                                                                                            " RAM\n                                                                                / " +
                                                                                            _vm.memoryFilter(
                                                                                              app.disk
                                                                                            ) +
                                                                                            " SSD"
                                                                                        ) +
                                                                                        "\n                                                                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "right-ft"
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "text-capitalize",
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "140px"
                                                                                },
                                                                                attrs: {
                                                                                  to:
                                                                                    "/applications/" +
                                                                                    app.uuid,
                                                                                  outline:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.getPackageStatus(
                                                                                      app
                                                                                    ) !==
                                                                                    "Active",
                                                                                  color:
                                                                                    "primary"
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "manage\n                                                                    "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Click to access your application control panel"
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              })
                            : [
                                _c(
                                  "v-flex",
                                  { staticStyle: { height: "200px" } },
                                  [
                                    _c(
                                      "Empty",
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "You do not have an active application"
                                          )
                                        ]),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "text-capitalize",
                                            attrs: {
                                              color: "primary",
                                              to: "/ordering"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Order a New\n                                            Product\n                                        "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-4" }),
          _c(
            "v-flex",
            { staticClass: "invoices" },
            [
              _c(
                "v-subheader",
                {
                  staticClass: "title px-0 mb-3 font-weight-black ",
                  staticStyle: { "justify-content": "space-between" }
                },
                [
                  _c("span", [_vm._v("Latest 5 Unpaid Invoices")]),
                  _c("v-spacer"),
                  _c(
                    "span",
                    { attrs: { title: "Check more invoices." } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "body-1",
                          attrs: { to: "/billing/invoices" }
                        },
                        [_vm._v("More")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-sheet",
                { attrs: { color: "transparent" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-0 invoice-table",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.invoicesList,
                          "headers-length": "50px",
                          "hide-actions": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "items",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("tr", [
                                  _c("td", [_vm._v(_vm._s(item.id))]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-xs-left",
                                      staticStyle: { "white-space": "nowrap" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.packages && item.packages.length
                                            ? item.packages[0].name
                                            : "--"
                                        ) +
                                          "\n                                    "
                                      )
                                    ]
                                  ),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(
                                      _vm._s(
                                        item.packages && item.packages.length
                                          ? item.packages[0].product_name
                                          : "--"
                                      ) +
                                        "\n                                    "
                                    )
                                  ]),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(item.due_date))
                                    )
                                  ]),
                                  _c("td", { staticClass: "text-xs-left" }, [
                                    _vm._v("$" + _vm._s(item.total))
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "text-xs-right" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-capitalize",
                                          staticStyle: { width: "140px" },
                                          attrs: {
                                            color: "primary",
                                            title:
                                              "Pay the invoice to extend the due date of the application.",
                                            to: "/invoice-detail/" + item.uuid,
                                            small: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " pay now\n                                        "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "no-data",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.invoicesLoading,
                                        expression: "invoicesLoading"
                                      }
                                    ],
                                    staticStyle: {
                                      "min-height": "400px",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c("fy-vue-skeleton", {
                                      attrs: {
                                        animation: "fade",
                                        rowOptions: {
                                          padding: "16px",
                                          gutter: "40"
                                        },
                                        childOptions: [
                                          {
                                            type: "repeat",
                                            repeat: 5,
                                            list: [
                                              {
                                                span: 24,
                                                type: "spaceline",
                                                options: {
                                                  height: "30px",
                                                  width: "100%"
                                                }
                                              },
                                              {
                                                span: 24,
                                                type: "card",
                                                options: {
                                                  height: "30px",
                                                  width: "100%"
                                                }
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.invoicesLoading,
                                        expression: "!invoicesLoading"
                                      }
                                    ],
                                    staticStyle: {
                                      "min-height": "200px",
                                      position: "relative"
                                    }
                                  },
                                  [
                                    _c("Empty", [
                                      _vm._v(
                                        "You have no unpaid invoices at the moment."
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-4" }),
          _c(
            "v-flex",
            { staticClass: "help-kb" },
            [
              _c(
                "v-subheader",
                {
                  staticClass: " title px-0 mb-3 font-weight-black ",
                  staticStyle: { "justify-content": "space-between" }
                },
                [
                  _c("span", [_vm._v("Help KB")]),
                  _c("v-spacer"),
                  _c(
                    "span",
                    { attrs: { title: "Check more documentation." } },
                    [
                      _c(
                        "router-link",
                        { staticClass: "body-1", attrs: { to: "/document" } },
                        [_vm._v("More")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-sheet",
                { attrs: { color: "transparent" } },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-0",
                      attrs: { "grid-list-xl": "", fluid: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        _vm._l(_vm.kbs, function(kb) {
                          return _c(
                            "v-flex",
                            {
                              key: kb.title,
                              staticClass: "kb-box",
                              attrs: { xs12: "", sm6: "", md6: "" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    " kb-title subheading px-0 font-weight-black ",
                                  attrs: { href: kb.link, target: "_blank" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        " kb-title subheading px-0 font-weight-black "
                                    },
                                    [_vm._v(_vm._s(kb.title))]
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "body-1",
                                  staticStyle: { color: "rgba(0,0,0,.54)" }
                                },
                                [_vm._v(_vm._s(kb.content))]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-4" }),
          _c(
            "v-flex",
            { staticClass: "popular" },
            [
              _c(
                "v-subheader",
                {
                  staticClass: " title px-0 mb-3 font-weight-black ",
                  staticStyle: { "justify-content": "space-between" }
                },
                [
                  _c("span", [_vm._v("Popular Products")]),
                  _c("v-spacer"),
                  _c(
                    "span",
                    { attrs: { title: "Check more products." } },
                    [
                      _c(
                        "router-link",
                        { staticClass: "body-1", attrs: { to: "/ordering" } },
                        [_vm._v("More")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-sheet",
                { attrs: { color: "transparent" } },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-0",
                      attrs: { "grid-list-xl": "", fluid: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        _vm._l(_vm.PopularProd, function(prod) {
                          return _c(
                            "v-flex",
                            {
                              key: prod.productName,
                              attrs: { xs6: "", sm4: "", md3: "", lg2: "" }
                            },
                            [_c("Product", { attrs: { product: prod } })],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }