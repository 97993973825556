var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product" },
    [
      _c("v-img", { attrs: { width: "160", src: _vm.product.logo } }),
      _c(
        "div",
        { staticClass: "product-mask" },
        [
          _vm.product.url
            ? _c(
                "v-btn",
                {
                  staticClass: "text-capitalize",
                  staticStyle: { width: "140px" },
                  attrs: {
                    href: _vm.isDev ? _vm.product.dev_url : _vm.product.url,
                    target: "_blank",
                    color: "#fff",
                    small: "",
                    outline: ""
                  }
                },
                [_vm._v("more details\n        ")]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "text-capitalize",
              staticStyle: { width: "140px" },
              attrs: {
                to: "/check/" + _vm.product.productName + "/-1",
                color: "primary",
                title: _vm.product.title,
                small: ""
              }
            },
            [_vm._v("order now\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }