import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=3cf4ef6f&"
import script from "./Product.vue?vue&type=script&lang=js&"
export * from "./Product.vue?vue&type=script&lang=js&"
import style0 from "./Product.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/test-tripanels/clusterpanels/client-panel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3cf4ef6f')) {
      api.createRecord('3cf4ef6f', component.options)
    } else {
      api.reload('3cf4ef6f', component.options)
    }
    module.hot.accept("./Product.vue?vue&type=template&id=3cf4ef6f&", function () {
      api.rerender('3cf4ef6f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Product.vue"
export default component.exports